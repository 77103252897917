import React from "react"

import Timeline from "@mui/lab/Timeline"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineDot from "@mui/lab/TimelineDot"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent"

import data from './data.json'
import {Typography} from "@mui/material"

const formatDate = date => new Date(date).toLocaleDateString(undefined, {
    dateStyle: 'medium'
})

const TimelineBuild = () => (
     <Timeline position="alternate" sx={{
         position: 'sticky',
         top: '8rem',
         p: 0,
         pb: '6px',
         mt: 0
     }}>
        {data.map((d, key) => (
            <TimelineItem key={key}>
                <TimelineOppositeContent align="right" variant="body2" color="text.secondary" sx={{
                    m: 'auto 0',
                    px: 0,
                    py: 2,
                }}>
                    {d.date ? formatDate(d.date) : 'Upcoming'}
                </TimelineOppositeContent>

                <TimelineSeparator sx={{mx: 3}}>
                    <TimelineDot color={d.complete ? 'primary' : 'grey'} sx={{boxShadow: 0}} />
                    <TimelineConnector/>
                </TimelineSeparator>

                <TimelineContent sx={{px: 0, pt: 1, pb: 3}}>
                    <Typography variant="h6" component="span" lineHeight={1}>{d.title}</Typography>
                    <Typography>{d.description}</Typography>
                </TimelineContent>
            </TimelineItem>
        ))}
    </Timeline>
)

export default TimelineBuild


