import {Link, useLocation} from 'react-router-dom'
import {Box, Container, Tab, Tabs} from "@mui/material"
import {getRoutePath, PATH_NAME_ABOUT, PATH_NAME_HOME, PATH_NAME_TIMELINE} from "../../Routes"

const items = [
    {
        value: PATH_NAME_HOME,
        label: "Home",
    },
    {
        value: PATH_NAME_TIMELINE,
        label: "Timeline",
    },
    {
        value: PATH_NAME_ABOUT,
        label: "About",
    },
];

const Navigation = () => {
    const location = useLocation()

    return (
        <Box boxShadow={8} sx={{
            backgroundColor: 'secondary.main',
            borderTop: '1px solid #bcc1ff47',
            zIndex: 'appBar',

            position: 'sticky',
            top: 0,
        }}>
            <Container>
                <Tabs
                    value={location.pathname.substring(1) || PATH_NAME_HOME}
                    textColor={'inherit'}
                    indicatorColor={'primary'}
                    centered
                >
                    {items.map(n => (
                        <Tab
                            key={n.value}
                            label={n.label}
                            value={n.value}
                            to={getRoutePath(n.value)}
                            component={Link}
                            sx={{
                                py: {
                                    xs: 2,
                                    lg: 3,
                                },
                                px: {
                                    xs: 3,
                                    sm: 6,
                                },
                                color: 'secondary.contrastText'
                            }}
                        />
                    ))}
                </Tabs>
            </Container>
        </Box>
)}

export default Navigation
