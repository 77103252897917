import * as React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

function MarkdownListItem(props) {
    return <Box component="li" sx={{mt: 1, typography: 'body1'}} {...props} />;
}

function MarkdownLink(props) {
    const url = document.createElement('a')
    url.href = props.href

    if (url.hostname !== window.location.hostname) {
        props = {
            ...props,
            target: "_blank",
            rel: "noreferrer"
        }
    }

    // @todo Fix MUI theming for link colors by default
    return <Link color="#148284" {...props} />;
}

function MarkdownImage(props) {
    return <Box component="img" {...props} sx={{
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
        margin: '2rem auto',
    }} />;
}

const options = {
    overrides: {
        h1: {
            component: Typography,
            props: {
                variant: 'h4',
                component: 'h1',
                my: 4,
            },
        },
        h2: {
            component: Typography,
            props: {
                variant: 'h2',
                component: 'h2',
                gutterBottom: true,
            },
        },
        p: {
            component: Typography,
            props: {paragraph: true},
        },
        a: {
            component: MarkdownLink,
        },
        li: {
            component: MarkdownListItem,
        },
        img: {
            component: MarkdownImage,
        },
    },
};

export default function Markdown(props) {
    return <ReactMarkdown options={options} {...props} />;
}
