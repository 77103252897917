import {Typography} from "@mui/material"

const TextIntro = () => (
    <>
        <Typography paragraph>
            That high performance biplane is the Pitts S-1-11B, which will become the second only of its type in the UK,
            with only a handful flying worldwide.
        </Typography>

        <Typography paragraph>
            We are a family with a passion for aviation who enjoy building, maintaining, and flying aircraft. The
            project for us started towards the end of 2015, and finally took to the skies for the first time on
            the 18th January 2023.
        </Typography>

        <Typography paragraph>
            Why? Because we love Pitts Specials. Their history, their character, and the way they fly. The "Super
            Stinker" was designed by Curtis Pitts to compete with the carbon monoplanes, and we plan to continue his
            vision by keeping biplanes competitive in Advanced aerobatic competitions in the UK.
        </Typography>
    </>
)

export default TextIntro
