import {
    Box,
    Container,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material"

import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import NavigationIcon from '@mui/icons-material/Navigation';

const Footer = () => (
    <Box sx={{
        backgroundColor: 'secondary.main',
        borderTop: theme => `2px solid ${theme.palette.primary.main}`,
        color: 'secondary.contrastText',
        mt: 4,
        py: 4,
    }}>
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                    <List disablePadding>
                        <ListItem>
                            <ListItemButton
                                disableGutters
                                component="a"
                                href="https://www.instagram.com/cartwright.aerobatics/"
                                title="Instagram"
                                target="_blank"
                                rel="noreferrer"
                                sx={{flexGrow: 0}}
                            >
                                <ListItemIcon sx={{color: '#e1306c', minWidth: 'unset', width: '1rem', mr: 2}}>
                                    <InstagramIcon/>
                                </ListItemIcon>
                                <ListItemText primary="@cartwright.aerobatics" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton
                                disableGutters
                                component="a"
                                href="https://www.facebook.com/ewokpitts"
                                title="Facebook"
                                target="_blank"
                                rel="noreferrer"
                                sx={{flexGrow: 0}}
                            >
                                <ListItemIcon sx={{color: '#4267B3', minWidth: 'unset', width: '1rem', mr: 2}}>
                                    <FacebookIcon />
                                </ListItemIcon>
                                <ListItemText primary="@ewokpitts" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton
                                disableGutters
                                component="a"
                                href="mailto:alex@gewok.co.uk"
                                title="Email"
                                target="_blank"
                                rel="noreferrer"
                                sx={{flexGrow: 0}}
                            >
                                <ListItemIcon sx={{color: '#ea4335', minWidth: 'unset', width: '1rem', mr: 2}}>
                                    <EmailIcon />
                                </ListItemIcon>
                                <ListItemText primary="alex@gewok.co.uk" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem>
                            <ListItemButton
                                disableGutters
                                component="a"
                                href="https://www.shropshireaeroclub.com/"
                                title="Visit us"
                                target="_blank"
                                rel="noreferrer"
                                sx={{flexGrow: 0}}
                            >
                                <ListItemIcon sx={{color: '#4c9a21', minWidth: 'unset', width: '1rem', mr: 2}}>
                                    <NavigationIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sleap Airfield, Shrewsbury, SY4 3HE, UK" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <List disablePadding>
                        <ListItem>
                            <ListItemText sx={{
                                pt: 1,
                                textAlign: {
                                    sm: 'right',
                                },
                            }}>
                                &copy; {new Date().getFullYear()} Cartwright Aerobatics
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>
    </Box>
)

export default Footer
