import {useEffect} from "react"

const useTitle = (title) => {
    useEffect(() => {
        const value = (typeof title === 'function') ? title() : title

        let docTitle = 'Building the Pitts S-1-11B - Cartwright Aerobatics'
        if (value !== null) {
            docTitle = `${value} | ${docTitle}`
        }

        document.title = docTitle
    }, [title])
}

export default useTitle
