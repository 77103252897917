import useTitle from "../hooks/useTitle"

import {Container, Typography} from "@mui/material"
import TimelineBuild from "../features/timeline/TimelineBuild"

const Timeline = () => {
    useTitle('Timeline')
    return (
        <Container>
            <Typography variant="h1" my={4}>
                Timeline of events
            </Typography>

            <TimelineBuild/>
        </Container>
    )
}

export default Timeline
