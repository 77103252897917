import './App.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {ThemeProvider} from "@mui/material"
import Theme from './Theme'

import Header from "./features/header/Header"
import Footer from "./features/footer/Footer"
import Navigation from "./features/navigation/Navigation"

import {BrowserRouter} from "react-router-dom"
import Routes from "./Routes"
import ScrollToTop from "./components/ScrollToTop"

const App = () => (
    <ThemeProvider theme={Theme}>
        <BrowserRouter>
            <ScrollToTop />

            <Header />
            <Navigation />

            <Routes />

            <Footer/>
        </BrowserRouter>
    </ThemeProvider>
)

export default App
