import React from "react"
import {Box, Typography} from "@mui/material"

const colorTeal = 'rgba(62,246,249,1)'
const ribbonHeight = '1.75rem'

const angelWidths = {
    xs: 30,
    sm: 50,
    lg: 75,
};

const getAngleWidths = callback => Object.keys(angelWidths).reduce((previous, key) => {
    previous[key] = callback(angelWidths[key])
    return previous
}, {})

const HeaderLogo = () => (
    <Box sx={{
        display: 'flex',
        width: '100%',
        mt: 5,
        filter: 'drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))',
    }}>
        <Box aria-hidden="true" sx={{
            flex: 1,
            mr: 0.5,
            mt: 1.75,
            height: ribbonHeight
        }}>
            <Box sx={{
                background: colorTeal,
                clipPath: 'polygon(0% 0%, 100% 0%, 99.2% 100%, 0% 100%)',
                height: '100%',
            }} />
        </Box>

        <Box sx={{
            textAlign: 'center',
        }}>
            <Typography variant="h1" sx={{
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: '48px !important',
                letterSpacing: '4px',
                width: '100%',
            }}>
                G-EWOK
            </Typography>
            <Typography paragraph>
                Cartwright Aerobatics
            </Typography>
        </Box>

        <Box aria-hidden="true" sx={{
            display: 'flex',
            justifyContent: 'end',
            flex: 1,
            ml: 1,
            mt: 1.75,
            height: ribbonHeight
        }}>
            <Box sx={{
                background: colorTeal,
                clipPath: 'polygon(100% 0%, 100% 50%, 100% 100%, 8px 100%, 0% 47%, 15px 0%)',
                height: '100%',
                flex: 1,
            }} />
            <Box sx={{
                flex: getAngleWidths(width => `0 0 ${width}px`),
                width: getAngleWidths(width => width),
            }}>
                <Box sx={{
                    background: colorTeal,
                    height: '100%',
                    // Calculate hypotenuse
                    width: getAngleWidths(width => `calc(${width * Math.sqrt(2)}px + ${ribbonHeight})`),

                    transform: 'rotate(45deg)',
                    transformOrigin: 'top left',
                }}/>
            </Box>
        </Box>
    </Box>
)

export default HeaderLogo
