import Carousel from 'react-material-ui-carousel'

import {Box, Typography} from "@mui/material"

import logoAzkoNobel from "../../../imgs/akzonobel-wordmark-white.png"
import logoImprint from "../../../imgs/imprint-grips.png"
import logoPhenix from "../../../imgs/phenix-industries.png"

const sponsors = [
    {
        name: "AkzoNobel",
        logo: logoAzkoNobel,
        url: "https://aerospace.akzonobel.com/"
    },
    {
        name: "ImprintGrips",
        logo: logoImprint,
        url: "https://imprintgrips.com/"
    },
    {
        name: "Phenix Industries",
        logo: logoPhenix,
        url: "https://phenixindustries.com/"
    },
]

const HeaderSponsors = () => (
    <Box sx={{
        backgroundColor: 'rgb(31, 33, 51, 0.5)',
        color: 'white',
        textAlign: 'center',
        p: 1,
        pb: 2,
        width: '100%',
    }}>
        <Typography
            component="h2"
            variant="body2"
            fontStyle="italic"
            mb={1}
        >
            Sponsored by
        </Typography>

        <Box sx={{
            '> div': {
                width: '100%',
                height: '20px',
                '& img': {
                    maxHeight: '20px',
                }
            }
        }}>
            <Carousel
                animation="slide"
                autoPlay={true}
                stopAutoPlayOnHover={false}
                indicators={false}
                navButtonsAlwaysInvisible={true}
                swipe={false}
                interval={4000}
                duration={700}
            >
                {sponsors.map((s, index) => (
                    <Box key={index} sx={{width: '100%'}}>
                        <a href={s.url} target="_blank" rel="noreferrer" title={s.name}>
                            <img src={s.logo} alt={s.name}/>
                        </a>
                    </Box>
                ))}
            </Carousel>
        </Box>
    </Box>
)

export default HeaderSponsors
