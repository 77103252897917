import {useState} from "react"
import useTitle from "../hooks/useTitle"

import fileContentAbout from "../content/about.md"

import {Container, Typography} from "@mui/material"
import Markdown from "../components/Markdown"

const About = () => {
    useTitle('About')
    const [content, setContent] = useState('')

    fetch(fileContentAbout)
        .then(response => response.text())
        .then(text => setContent(text))

    return (
        <Container>
            <Typography variant="h1" my={4}>
                About the project
            </Typography>

            <Markdown>{content}</Markdown>
        </Container>
    )
}

export default About
