import useTitle from "../hooks/useTitle"

import {Container, Typography} from "@mui/material"
import TextIntro from "../features/text/TextIntro"
import Gallery from "../features/gallery/Gallery"

const Home = () => {
    useTitle('Home')

    return (
        <Container>
            <Typography variant="h1" my={4}>
                We're building a high performance biplane
            </Typography>

            <TextIntro/>
            <Gallery/>
        </Container>
    )
}

export default Home
