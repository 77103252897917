import React from "react"
import {Box} from "@mui/material"

import HeroImg from "../../imgs/SC5D2932.jpg"
import Logo from "./components/logo"
import Sponsors from "./components/sponsors"

const Header = () => (
    <header>
        <Box boxShadow={8} sx={{
            background: '#1a1c28',
            color: 'white',
            height: '55vh',

            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',

            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(34, 74, 81, 0.6)), url(${HeroImg}) `,
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
        }}>
            <Logo />
            <Sponsors />
        </Box>
    </header>
)

export default Header
