export const trackEvent = (event, params = {}) => {
    try {
        window.dataLayer && window.dataLayer.push({
            event,
            ...params
        })
    } catch (err) {
        console.warn(`Failed to push "${event}" to dataLayer`, err)
    }
}
