import {createTheme, responsiveFontSizes} from "@mui/material"

let theme = createTheme({
    palette: {
        primary: {
            main: '#3EF6F9',
        },
        secondary: {
            main: '#1f2133',
        },
    },
    typography: {
        fontSize: 12,
        h1: {
            fontSize: "3rem",
            fontWeight: 700,
            textAlign: 'left',
        },
        h2: {
            fontSize: "1.75rem",
            fontWeight: 700,
            textAlign: 'left',
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme
