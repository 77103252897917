import React from 'react';
import {Routes, Route, generatePath} from 'react-router-dom';

import Home from "./views/Home"
import About from "./views/About"
import Timeline from "./views/Timeline"

export const PATH_NAME_HOME = 'home'
export const PATH_NAME_TIMELINE = 'timeline'
export const PATH_NAME_ABOUT = 'about'

export const paths = {
    [PATH_NAME_HOME]: '/',
    [PATH_NAME_TIMELINE]: '/timeline',
    [PATH_NAME_ABOUT]: '/about',
}

export const getRoutePath = (name, params) => generatePath(paths[name], params)
export const getRouteUrl = (name, params) => `${window.location.origin}${getRoutePath(name, params)}`

const AppRoutes = () => (
    <Routes>
        <Route path={paths.home} exact element={<Home />} />
        <Route path={paths.timeline} element={<Timeline />} />
        <Route path={paths.about} element={<About />} />
    </Routes>
)

export default AppRoutes
