import {useState} from "react"
import {trackEvent} from "../../utilities/tracking"

import {
    Box,
    FormControl,
    ImageList,
    ImageListItem,
    InputLabel,
    MenuItem, Modal,
    Select,
    Typography,
    useMediaQuery
} from "@mui/material"

import images from './images.json'

let modalOpenedAt

const cdn = "https://cdn.gewok.co.uk/gallery"

const Gallery = () => {
    const useNativeSelect = useMediaQuery(theme => theme.breakpoints.down('md'))

    const years = Object.keys(images).sort().reverse()

    const [year, setYear] = useState(years[0])
    const [selectedImg, setSelectedImg] = useState(null)

    const selectYear = year => {
        trackEvent('select_gallery_year', {year})
        setYear(year)
    }

    const openModal = image => {
        modalOpenedAt = new Date()
        trackEvent('open_gallery_modal', {image})
        setSelectedImg(image)
    }

    const closeModal = () => {
        const modalClosedAt = new Date()
        const duration = ((modalClosedAt.getTime() - modalOpenedAt.getTime()) / 1000).toFixed(2)
        modalOpenedAt = null

        trackEvent('close_gallery_modal', {
            image: selectedImg,
            duration,
        })

        setSelectedImg(null)
    }

    return (
        <Box mt={4}>
            <Typography variant="h2" gutterBottom>
                Photos
            </Typography>

            <Box sx={{
                background: 'white',
                py: 3,
                position: 'sticky',
                top: {
                    xs: '3rem',
                    lg: '4rem',
                },
                zIndex: '1000',
            }}>
                <FormControl fullWidth>
                    <InputLabel id="gallery-year">Year</InputLabel>
                    <Select
                        labelId="gallery-year"
                        id="gallery-year-select"
                        value={year}
                        label="Year"
                        onChange={(e) => selectYear(e.target.value)}
                        native={useNativeSelect}
                    >
                        {years.map(y => useNativeSelect
                            ? <option key={y} value={y}>{y}</option>
                            : <MenuItem key={y} value={y}>{y}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>

            <ImageList gap={4} cols={useMediaQuery(theme => theme.breakpoints.up('md')) ? 4 : 3}>
                {images[year].map((img, index) => (
                    <ImageListItem key={`${year}-${index}`} onClick={() => openModal(img)} sx={{
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}>
                        <img
                            src={`${cdn}/thumbnails/${img}`}
                            alt={`G-EWOK photo ${index}`}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            {selectedImg !== null && (
                <Modal open={true} onClose={closeModal}>
                    <Box onClick={closeModal} sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: 24,

                        '& img': {
                            display: 'block',
                            maxWidth: '95vw',
                            maxHeight: '95vh',
                        }
                    }}>
                        <img src={`${cdn}/${selectedImg}`} alt="" />
                    </Box>
                </Modal>
            )}
        </Box>
    )
}

export default Gallery
